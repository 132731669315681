import Head from '@gain/modules/head'
import { Asset, TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { createExternalLink } from '@gain/utils/common'
import { useElementWidth } from '@gain/utils/dom'
import Grid from '@mui/material/Grid2'
import { useRef } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import CardAssetFte, { useHasFteMeasurements } from '../../../features/asset/asset-fte-card'
import ShareFeedbackBar from '../../../features/feedback/share-feedback-bar'
import { SHARE_FEEDBACK_ASSET_INFO } from '../../../features/feedback/share-feedback-info-constants'
import { ASSET_PAGE_PATH, useAssetPageParams } from '../../utils'
import { AssetAutomatedRequestDetailed } from '../asset-automated'
import { shouldShowFinancialsTab } from '../asset-financials'
import AssetAssessmentCard from './asset-assessment-card'
import AssetBusinessCard from './asset-business-card'
import AssetFinancialsCard from './asset-financials-card'
import AssetMarketCard from './asset-market-card'
import AssetNewsCard from './asset-news-card'
import AssetOwnershipCard from './asset-ownership-card'
import { useListAssetArticles } from './asset-summary-hooks'

interface AssetSummaryProps {
  asset: Asset
}

export default function AssetSummary({ asset }: AssetSummaryProps) {
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)
  const params = useAssetPageParams()
  const articles = useListAssetArticles(params.id)
  const history = useHistory()

  const isHorizontalLayout = width > 951

  const hasArticles = !articles.loading && !articles.empty
  const hasFteMeasurements = useHasFteMeasurements(asset)

  const hasFinancialResults = shouldShowFinancialsTab(asset)
  const showFteInsteadOfFinancialsForAutomatedAssets =
    asset.profileType === AssetProfileType.Automated && hasFteMeasurements && !hasFinancialResults

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Summary</title>
      </Head>
      <Grid
        ref={ref}
        spacing={2}
        sx={{ maxWidth: '100%' }}
        container>
        {isHorizontalLayout && asset.profileType === AssetProfileType.Automated && (
          <Grid size={12}>
            <AssetAutomatedRequestDetailed asset={asset} />
          </Grid>
        )}

        <Grid size={12}>
          <Grid
            alignItems={'stretch'}
            direction={'row'}
            spacing={2}
            container>
            <Grid size={{ lg: 4, xs: 12 }}>
              <Grid sx={{ height: '100%' }}>
                <AssetBusinessCard
                  asset={asset}
                  sx={{
                    minHeight: {
                      lg: showFteInsteadOfFinancialsForAutomatedAssets ? 678 : 604,
                    },
                  }}
                />
              </Grid>
            </Grid>
            {!isHorizontalLayout && asset.profileType === AssetProfileType.Automated && (
              <Grid size={12}>
                <AssetAutomatedRequestDetailed asset={asset} />
              </Grid>
            )}
            <Grid
              size={{ lg: 8, xs: 12 }}
              spacing={2}
              container>
              <Grid size={{ xs: 12, md: 6 }}>
                {showFteInsteadOfFinancialsForAutomatedAssets ? (
                  <CardAssetFte
                    asset={asset}
                    showDivider
                  />
                ) : (
                  <AssetFinancialsCard
                    asset={asset}
                    sx={{
                      minHeight: {
                        md: 292,
                      },
                    }}
                    fullHeight
                  />
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <AssetOwnershipCard
                  asset={asset}
                  sx={{
                    minHeight: {
                      md: 292,
                    },
                  }}
                  fullHeight
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <AssetMarketCard
                  asset={asset}
                  sx={{
                    minHeight: {
                      md: 292,
                    },
                  }}
                  fullHeight
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <AssetAssessmentCard
                  asset={asset}
                  sx={{
                    minHeight: {
                      md: 292,
                    },
                  }}
                  fullHeight
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {hasArticles && (
          <Grid size={12}>
            <AssetNewsCard
              articles={articles.data.items}
              fullHeight
            />
          </Grid>
        )}

        <Grid size={12}>
          <ShareFeedbackBar
            attributes={[
              { type: TicketAttributeType.CompanyName, value: asset.generalInfo?.name || '' },
              {
                type: TicketAttributeType.GainProCompanyUrl,
                value: createExternalLink(
                  history.createHref({
                    pathname: generatePath(ASSET_PAGE_PATH, {
                      name: asset.generalInfo?.name || '-',
                      id: asset.id,
                    }),
                  })
                ),
              },
              {
                type: TicketAttributeType.CompanyRegion,
                value: asset.generalInfo?.headquarters || '',
              },
            ]}
            disabledInfoFields={[
              {
                label: 'Company name',
                value: asset.generalInfo?.name || '',
              },
            ]}
            infoDescriptions={SHARE_FEEDBACK_ASSET_INFO}
            title={'Give feedback on company profile'}
            type={TicketType.ShareCompanyFeedback}
          />
        </Grid>
      </Grid>
    </>
  )
}
