import { CompanyIcon } from '@gain/components/icons'
import Head from '@gain/modules/head'
import { Asset, TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import { createExternalLink } from '@gain/utils/common'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ReactElement } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import ProfileTabBar, { ProfileTabContainer } from '../../../common/profile-tab-bar'
import CardAssetFte, { useHasFteMeasurements } from '../../../features/asset/asset-fte-card'
import BookmarkAddButton from '../../../features/bookmarks-list/bookmark-add-object/bookmark-add-button'
import ButtonAddToDealCloud from '../../../features/dealcloud'
import ShareFeedbackBar from '../../../features/feedback/share-feedback-bar'
import { SHARE_FEEDBACK_ASSET_INFO } from '../../../features/feedback/share-feedback-info-constants'
import MobilePageHeader from '../../../layout/mobile-page-header'
import { ASSET_PAGE_PATH, useAssetPageParams } from '../../utils'
import AssetHeaderSubtitle, { useHasSubtitle } from '../asset-header/asset-header-subtitle'
import AssetAutomatedDetails from './asset-automated-details'
import AssetAutomatedRequestDetailed from './asset-automated-request-detailed'

interface AssetAutomated {
  asset: Asset
  isBrowserExtension?: boolean
}

export default function AssetAutomated({ asset, isBrowserExtension }: AssetAutomated) {
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'))
  const isMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const params = useAssetPageParams()
  const history = useHistory()

  const hasFteMeasurements = useHasFteMeasurements(asset)

  const hasSubtitle = useHasSubtitle(asset)

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name}</title>
      </Head>

      {isXs && !isBrowserExtension && (
        <MobilePageHeader
          actions={
            <BookmarkAddButton
              color={'default'}
              fontSize={24}
              objectId={asset.id}
              bold
            />
          }
          title={asset.generalInfo?.name}
        />
      )}

      <ProfileTabBar
        defaultIcon={CompanyIcon}
        infoActions={
          [
            !isBrowserExtension && ( // TODO this is temporary until we deployed the bookmark refactor
              <BookmarkAddButton
                key={'btn-add-to-list'}
                objectId={asset.id}
              />
            ),
            <ButtonAddToDealCloud
              key={'btn-add-to-dealcloud'}
              assetId={asset.id}
              variant={isBrowserExtension || isMdDown ? 'icon' : 'contained'}
            />,
          ].filter(Boolean) as ReactElement[]
        }
        logoFileUrl={asset.generalInfo?.logoFileUrl || undefined}
        subtitle={hasSubtitle ? <AssetHeaderSubtitle asset={asset} /> : undefined}
        title={
          <Stack
            direction={'row'}
            gap={1}>
            {asset.generalInfo?.name || params.name}

            <Chip
              color={'warning'}
              label={'Generative AI'}
            />
          </Stack>
        }
        replaceUrl
        sticky
      />

      <ProfileTabContainer
        maxWidth={'lg'}
        stickyVariant={'no-tabs'}
        disableGuttersTop>
        <Grid
          spacing={2}
          container>
          <Grid size={12}>
            <AssetAutomatedRequestDetailed asset={asset} />
          </Grid>

          <Grid size={{ md: 6, xs: 12 }}>
            <AssetAutomatedDetails
              asset={asset}
              sx={{
                minHeight: {
                  lg: 375,
                },
              }}
            />
          </Grid>
          {hasFteMeasurements && (
            <Grid size={{ md: 6, xs: 12 }}>
              <CardAssetFte
                asset={asset}
                showDivider
              />
            </Grid>
          )}

          <Grid size={12}>
            <ShareFeedbackBar
              attributes={[
                { type: TicketAttributeType.CompanyName, value: asset.generalInfo?.name || '' },
                {
                  type: TicketAttributeType.GainProCompanyUrl,
                  value: createExternalLink(
                    history.createHref({
                      pathname: generatePath(ASSET_PAGE_PATH, {
                        name: asset.generalInfo?.name || '-',
                        id: asset.id,
                      }),
                    })
                  ),
                },
                {
                  type: TicketAttributeType.CompanyRegion,
                  value: asset.generalInfo?.headquarters || '',
                },
              ]}
              disabledInfoFields={[
                {
                  label: 'Company name',
                  value: asset.generalInfo?.name || '',
                },
              ]}
              infoDescriptions={SHARE_FEEDBACK_ASSET_INFO}
              title={'Give feedback on company profile'}
              type={TicketType.ShareCompanyFeedback}
            />
          </Grid>
        </Grid>
      </ProfileTabContainer>
    </>
  )
}
